<template>
    <v-card class="rounded-lg" elevation="10" height="auto" hover outlined width="100%"
        v-on:click="showProfile(item.slu)">
        <v-img :lazy-src="require('@/assets/images/no-image.jpg')" :src="item.pictures_portal[0] != null
                ? item.pictures_portal[0].url
                : require('@/assets/images/no-image.jpg')
            " class="white--text align-end justify-start" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
            height="300" position="center">
            <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular color="grey lighten-5" indeterminate>
                    </v-progress-circular>
                </v-row>
            </template>

            <div class="white--text mx-2">
                <p class="text-left font-weight-light text-h5">
                    {{ item.name }}
                </p>
            </div>
        </v-img>
        <TypeEnityDiscView :item="item" />
    </v-card>
</template>
<script>
import TypeEnityDiscView from "@/components/type-entity-view/TypeEnityDiscView";

export default {
    name: "CardRestaurant",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    components: {
        TypeEnityDiscView
    },
    methods: {
        showProfile(idRestaurant) {
            this.$router.push({
                name: "RestaurantProfile",
                params: {
                    id: idRestaurant,
                },
            });
            this.$emit("update-route", { id: idRestaurant });
        },
    }
}
</script>
