<template>
  <restaurant-profile-component @update-title="changeTitle" />
</template>

<script>
import RestaurantProfileComponent from "@/components/restaurants-leisures/RestaurantProfileComponent";

export default {
  name: "RestaurantProfile",
  title: "Perfil del Restaurant | Baja California Health Tourism",
  components: { RestaurantProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
